
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'
import {EscapeShellStringsTool} from 'components/EscapeShellStringsTool'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Have you lost too much time with `}<em parentName="p">{`single quotes`}</em>{`, `}<em parentName="p">{`double quotes`}</em>{` and `}<em parentName="p">{`backslashes`}</em>{` among `}<strong parentName="p">{`shell arguments`}</strong>{`?`}</p>
    <p>{`Feel free to use this simple tool to escape strings shell commands.`}</p>
    <EscapeShellStringsTool mdxType="EscapeShellStringsTool" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    